import { fileNames } from 'Constants/imageUrls';

export const COLUMN_LINKS = [
    'popular-categories',
    'popular-locations',
    'popular-searches',
    'other-countries',
    'corporate-links',
    'popular-sell-location',
    'popular-sell-brand'
];

export const DISABLED_SELL_FLOW_LINKS = [
    'popular-sell-location',
    'popular-sell-brand'
];

export const TOP_COLUMN_LINKS = COLUMN_LINKS.filter(column => column !== 'other-countries');
export const COLUMN_ICONS = [
    'social-networks',
    'mobile-apps'
];

export const TOP_SECTIONS_TYPE = {
    H1_BREADCRUMB: 'H1_BREADCRUMB',
    RELATED_SEARCHES: 'RELATED_SEARCHES'
};

export const CARET_ICON = {
    DOWN: 'arrowDown',
    RIGHT: 'arrowRight'
};

export const FOOTER_BRANDS = [
    { name: 'olx', href: '', icon: fileNames.CT_OLX_LOGO },
    { name: 'carwale', href: 'https://www.carwale.com', icon: fileNames.CT_CARWALE_LOGO },
    { name: 'bikewale', href: 'https://www.bikewale.com', icon: fileNames.CT_BIKEWALE_LOGO },
    { name: 'cartrade', href: 'https://www.cartrade.com', icon: fileNames.CT_CARTRADE_LOGO },
    { name: 'mobility_outlook', href: 'https://www.mobilityoutlook.com', icon: fileNames.CT_MOBILITY_OUTLOOK_LOGO }
];

export const CAR_TRADE_TECH_LOGO = fileNames.CT_CARTRADE_TECH_LOGO;
