/* global window */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withRouter from 'HOCs/withRouter';
import classNames from 'classnames';
import { FormattedMessage as Translation } from 'react-intl';
import NewIconButton from 'Components/ThematicNewIconButton/ThematicNewIconButton';

import OutsideClickListener from 'Components/OutsideClickListener/OutsideClickListener';
import { navigationTreeSelector, suggestedNavigationTreeCategoriesSelector, updatedSuggestedNavigationTreeCategoriesSelector, getOlxRenewCategoryForL2 } from 'Selectors/navigationTree';
import withConfig from 'HOCs/withConfig/withConfig';
import withTrack from 'HOCs/withTrack/withTrack';
import { buildURL } from 'Helpers/url';
import { selectedLocationSelector, isCurrentGPSLocationSelector } from 'Selectors/location';
import {
    TAP_ALLCATEGORIES,
    BROWSE_CATEGORY_SELECT,
    HEADER_SUGGESTIONS,
    BROWSE_SUBCATEGORY_SELECT
} from 'Constants/tracking';
import SearchAllSubCategories from './SearchAllSubCategories';
import SuggestedCategories from './SuggestedCategories';
import SearchAllSubCategoryRow from './SearchAllSubCategoryRow';
import css from './SearchAllCategories.scss';
import { getNavigationTree } from 'Actions/navigationTree';
import withSessionLong from 'HOCs/withSessionLong/withSessionLong';
import { getLoggedUser } from 'Selectors/user';
import { configSelector } from '../../../selectors/config';
import { OLX_RENEW_MOBILE_NAME, OLX_RENEW_MOBILE_CAT_ALL_L2 } from 'Constants/constants';

export class SearchAllCategories extends React.PureComponent {
    static propTypes = {
        categories: PropTypes.array,
        className: PropTypes.string,
        track: PropTypes.func.isRequired,
        suggestedCategories: PropTypes.array,
        router: PropTypes.shape({
            push: PropTypes.func.isRequired
        }).isRequired,
        selectedLocation: PropTypes.object,
        isCurrentGPSLocation: PropTypes.bool,
        getCategories: PropTypes.func.isRequired,
        config: PropTypes.shape({
            get: PropTypes.func.isRequired
        }).isRequired,
        trackOrigin: PropTypes.string,
        userSessionLong: PropTypes.string,
        userId: PropTypes.string,
        olxRenewCategoryForL2: PropTypes.object

    }

    static defaultProps = {
        categories: [],
        onChange: () => {},
        className: '',
        trackOrigin: 'home'
    }

    constructor(props) {
        super(props);
        this.state = {
            isDropdownOpen: false

        };
    }

    componentDidMount() {
        this.props.getCategories();
    }

    closeDropdown = () => this.setState({ isDropdownOpen: false });

    onClickOnCategory = category => {
        this.handleTrackingEvent(category);
        if (category?.id === OLX_RENEW_MOBILE_CAT_ALL_L2) {
            window.location.href = category?.redirection_url;
        }
        else {
            this.handleCategoryChange(category);
        }
    };

    handleOnSuggestedClick = category => {
        this.handleTrackingEvent(category);
        if (category?.id === OLX_RENEW_MOBILE_NAME) {
            window.location.href = category?.redirection_url;
        }
        else {
            this.handleCategoryChange(category, HEADER_SUGGESTIONS);
        }
    };
    handleTrackingEvent = category => {
        this.fireTrackingEvent(category);
        this.closeDropdown();
    };

    fireTrackingEvent = category => {
        if (!category) {
            return;
        }

        let eventName;
        let trackInfo;

        if (category.parent_id) {
            eventName = BROWSE_SUBCATEGORY_SELECT;
            trackInfo = {
                category_level1_id: category.parent_id,
                category_level2_id: category?.id === OLX_RENEW_MOBILE_CAT_ALL_L2 ? category.category_id : category.id
            };
        }
        else {
            eventName = BROWSE_CATEGORY_SELECT;
            trackInfo = {
                category_level1_id: category?.id === OLX_RENEW_MOBILE_NAME ? category.category_id : category.id
            };
        }
        this.props.track(eventName, {
            select_from: 'subheader',
            ...trackInfo
        });
    }
    getCurrentLocation = () => {
        const { selectedLocation, isCurrentGPSLocation } = this.props;
        const location = isCurrentGPSLocation ? null : selectedLocation;

        return location;
    }

    handleCategoryChange = category => {
        const location = this.getCurrentLocation();

        this.props.router.push(buildURL({ location, category }));
        window.scrollTo(0, 0);
    };

    openDropdown = () => {
        if (!this.state.isDropdownOpen) {
            this.props.track(TAP_ALLCATEGORIES);
        }
        return this.setState(state => ({ isDropdownOpen: !state.isDropdownOpen }));
    };

    renderCategoryList = () => {
        const { isDropdownOpen } = this.state;
        const { olxRenewCategoryForL2 } = this.props;

        return (
            <div
                key="categoriesDropdown"
                className={ classNames(css.dropdownItems, { [css.show]: isDropdownOpen }) }
                data-aut-id="categories"
            >
                {
                    this.props.categories.map(category => {
                        const isSoloSubCategory = category.sub_categories?.length === 1;
                        const finalCategory = isSoloSubCategory ? category.sub_categories[0] : category;
                        const onCategoryClick = () => this.onClickOnCategory(finalCategory);

                        if (olxRenewCategoryForL2 && Object.entries(olxRenewCategoryForL2).length > 0
                        && olxRenewCategoryForL2.enabled && category?.sub_categories[0]?.id !== olxRenewCategoryForL2?.id
                        && category?.id === olxRenewCategoryForL2?.parent_category_id
                        ) {
                            category?.sub_categories?.unshift(olxRenewCategoryForL2);
                        }
                        return (
                            <div key={ category.id } className={ css.categoryContainer }>
                                <SearchAllSubCategoryRow
                                    // eslint-disable-next-line react/jsx-no-bind
                                    onClick={ onCategoryClick }
                                    category={ category }
                                    className={ css.categoryTitle }
                                    showCategoryTag={ true }
                                />
                                { category.sub_categories
                                && !isSoloSubCategory
                                    && <SearchAllSubCategories
                                        categories={ category.sub_categories }
                                        onClick={ this.onClickOnCategory }
                                        parentId={ category.id }
                                        showCategoryTag={ true }
                                    />
                                }
                            </div>
                        );
                    })
                }
            </div>
        );
    }

    render() {
        const { className, suggestedCategories, config, userSessionLong, userId } = this.props;
        const location = this.getCurrentLocation();

        return (
            <OutsideClickListener onOutsideClick={ this.closeDropdown } className={ css.container }>
                <div className={ css.dropdownList }>
                    <div onClick={ this.openDropdown } className={ css.allCategories } data-aut-id="allCategories">
                        <span className={ css.title }><Translation id="allCategories" /></span>
                        <span className={ classNames(css.dropdownChevron, { [css.dropdownChevronUp]: this.state.isDropdownOpen }) } >
                            <NewIconButton
                                icon="arrowDown"
                                animation={ false }
                            />
                        </span>
                    </div>
                    { !!suggestedCategories && <SuggestedCategories
                        onClick={ this.handleOnSuggestedClick }
                        categories={ suggestedCategories }
                        location={ location }
                        config={ config }
                        track={ this.props.track }
                        trackOrigin={ this.props.trackOrigin }
                        userSessionLong={ userSessionLong }
                        userId={ userId }
                    /> }
                </div>
                <div className={ classNames(css.dropdown, className) } data-aut-id="categoryList">
                    {this.renderCategoryList()}
                </div>
            </OutsideClickListener>
        );
    }
}

const mapStateToProps = (state, props) => {
    const suggestedCategories = suggestedNavigationTreeCategoriesSelector(state, props);
    const olxRenewCategoryFeature = configSelector(state)?.features;
    const olxRenewCategoryForL2 = getOlxRenewCategoryForL2(olxRenewCategoryFeature, state);

    return {
        trackOrigin: state.track.origin,
        categories: navigationTreeSelector(state),
        selectedLocation: selectedLocationSelector(state),
        isCurrentGPSLocation: isCurrentGPSLocationSelector(state),
        suggestedCategories: updatedSuggestedNavigationTreeCategoriesSelector(suggestedCategories, olxRenewCategoryFeature, state),
        userId: getLoggedUser(state).id,
        olxRenewCategoryForL2
    };
};

const mapDispatchToProps = dispatch => ({
    getCategories: () => dispatch(getNavigationTree())
});

export default compose(
    withRouter,
    withConfig,
    withTrack,
    withSessionLong,
    connect(mapStateToProps, mapDispatchToProps)
)(SearchAllCategories);
