/* global window */
import React from 'react';
import * as ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './AvatarDropdown.scss';
import Icon from 'Components/ThematicIcon/ThematicIcon';
import { Avatar } from 'panamera-react-ui';
import AsyncMyAccount from 'Components/MyAccount/AsyncMyAccount';
import { connect } from 'react-redux';

import RedDot from '../../pages/MyZone/RedDot/RedDot';

export class AvatarDropdown extends React.PureComponent {
    static propTypes = {
        imageURl: PropTypes.string.isRequired,
        userName: PropTypes.string,
        isMyZoneFinanceEnabled: PropTypes.bool,
        isMyZoneEnabled: PropTypes.bool.isRequired,
        threads: PropTypes.array.isRequired
    };

    constructor(props) {
        super(props);

        this.avatarElement = null;

        this.setAvatarElementRef = element => {
            this.avatarElement = element;
        };

        this.state = {
            showDropdown: false
        };
    }

    closeDropdown = () => {
        this.setState({
            showDropdown: false
        });
    }

    toggleDropdown = () => {
        this.setState({
            showDropdown: !this.state.showDropdown
        });
    }

    getDropdownRightPosition() {
        if (!window || !this.avatarElement) {
            return 0;
        }

        return window.innerWidth - this.avatarElement.getBoundingClientRect().right;
    }

    render() {
        const { showDropdown } = this.state;
        const { userName, isMyZoneEnabled, threads, isMyZoneFinanceEnabled } = this.props;
        const avatarClassName = css.avatarContainer;

        return (
            <>
                <div
                    className={ avatarClassName }
                    data-aut-id="iconProfile"
                    onClick={ this.toggleDropdown }
                    ref={ this.setAvatarElementRef }
                >
                    {isMyZoneEnabled && <RedDot className={ css.redDotContainer } threads={ threads } /> }
                    {<Avatar
                        diameter="35px"
                        className={ css.avatar }
                        image={ this.props.imageURl }
                    />
                    || <div className={ classNames(css.avatarInitials) } >{userName && userName[0].toUpperCase()}</div>
                    }
                    <Icon
                        icon="arrowDown"
                        size={ 20 }
                    />
                    { showDropdown
                        && ReactDOM.createPortal(<div className={ css.dropDownList } style={ { right: this.getDropdownRightPosition() } }>
                            <AsyncMyAccount isMyZoneFinanceEnabled={ isMyZoneFinanceEnabled } selectFrom="header" />
                        </div>, document.body)
                    }
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        isMyZoneEnabled: state.isMyZoneEnabled,
        threads: state.chat?.threads || []
    };
};

export default connect(mapStateToProps)(AvatarDropdown);
