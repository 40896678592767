import { PLATFORM } from 'Constants/device.APP_TARGET';
import { shouldRetry } from 'Helpers/ssrRetry';

const BXP_REQUIRED_HEADER = { 'x-panamera-client-id': `web-${PLATFORM}` };

export const bxpNavigationTreeUrl = '/api/bxp/v2/navigation-trees/default';
/**
 * Method to the the navigation tree from Tanak service
 */
export const getNavigationTree = (retrySSRFailed = false) => (dispatch, getState, { get }) => {
    const { navigationTree } = getState();
    const isFetching = navigationTree && navigationTree.isFetching;
    const hasData = navigationTree && navigationTree.data;
    const isError = navigationTree && navigationTree.isError;
    const retry = retrySSRFailed && shouldRetry(navigationTree.errorMetadata);

    if (hasData || isFetching || (isError && !retry)) {
        return Promise.resolve();
    }

    return dispatch(get(bxpNavigationTreeUrl, 'BXP_NAVIGATION_TREE', null, BXP_REQUIRED_HEADER));
};
