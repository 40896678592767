import React, { useEffect } from 'react';
import css from './MgidComponent.scss';
import { isMobile } from 'Helpers/devices';

function MgidComponent() {
    useEffect(() => {
        // Create script element for the main widget script
        const script = document.createElement('script');

        script.src = 'https://jsc.mgid.com/site/968110.js';
        script.async = true;
        document.body.appendChild(script);

        // Create and add the inline script for loading the widget
        const inlineScript = document.createElement('script');

        inlineScript.text = `
            (function(w, q) {
              w[q] = w[q] || [];
              w[q].push(["_mgc.load"]);
            })(window, "_mgq");
          `;
        document.body.appendChild(inlineScript);

        // Clean up the scripts when the component is unmounted
        return () => {
            document.body.removeChild(script);
            document.body.removeChild(inlineScript);
        };
    }, []);
    return (
        <div data-aut-id="mgidWrapper" className={ isMobile ? css.mgidMobileWrapper : css.mgidDesktopWrapper }>
            <div data-type="_mgwidget" data-widget-id="1638204" />
        </div>
    );
}

export default MgidComponent;
