import { fileNames } from 'Constants/imageUrls';
import {
    DESKTOP_OLX_LOGO_SIZES,
    DESKTOP_AUTOS_LOGO_SIZES,
    MOBILE_AUTOS_LOGO_SIZES
} from 'Constants/header';
import { isMobile } from 'Helpers/devices';

export const getLogoData = (showAutosLogo = false, staticAssetPath = '') => {
    if (showAutosLogo) {
        return {
            logoUrl: `${ staticAssetPath }${ fileNames.OLX_AUTOS_TEAL_LOGO }`,
            logoSize: DESKTOP_AUTOS_LOGO_SIZES.HEIGHT,
            logoClass: '',
            logoWidth: isMobile ? MOBILE_AUTOS_LOGO_SIZES.WIDTH : DESKTOP_AUTOS_LOGO_SIZES.WIDTH,
            logoHeight: isMobile ? MOBILE_AUTOS_LOGO_SIZES.HEIGHT : DESKTOP_AUTOS_LOGO_SIZES.HEIGHT
        };
    }

    return {
        logoUrl: '',
        logoSize: DESKTOP_OLX_LOGO_SIZES.HEIGHT,
        logoClass: '',
        logoWidth: DESKTOP_OLX_LOGO_SIZES.WIDTH,
        logoHeight: DESKTOP_OLX_LOGO_SIZES.HEIGHT
    };
};
