/**
 * Sets features variable in component present in the session storage
 * @param {*} Component
 */

import React from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';
import { compose } from 'redux';
import { connect } from 'react-redux';

const withSessionFeature = Component => {
    const ComponentWithSessionFeature = props => {
        return <Component { ...props } />;
    };

    const mapStateToProps = state => {
        return {
            sessionFeatures: state.api.sessionFeatures || []
        };
    };

    ComponentWithSessionFeature.displayName = `withSessionFeature(${Component.displayName || Component.name})`;

    return compose(
        connect(mapStateToProps)
    )(hoistNonReactStatic(ComponentWithSessionFeature, Component));
};

export default withSessionFeature;
