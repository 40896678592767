import React from 'react';
import ImageWrapper from 'Components/ImageWrapper/ImageWrapper';
import css from './FooterBrand.desktop.scss';
import { SVG_TYPE } from 'Constants/images';
import { FOOTER_BRANDS } from 'Constants/footer';
import { fileNames } from 'Constants/imageUrls';

const FooterBrand = () => {
    const getBrands = () => {
        return FOOTER_BRANDS.map((brand, index) => (
            <div key={ index } className={ css.company_logo }>
                {brand.href === '' ? (
                    <ImageWrapper
                        fileName={ brand.icon }
                        types={ SVG_TYPE }
                        alt={ brand.name }
                        className={ css.logoImg }
                    />
                ) : (
                    <a href={ brand.href } target="_blank" rel="noopener noreferrer">
                        <ImageWrapper
                            fileName={ brand.icon }
                            types={ SVG_TYPE }
                            alt={ brand.name }
                            className={ css.logoImg }
                        />
                    </a>
                )}
            </div>
        ));
    };

    return (
        <div className={ css.portfolio }>
            <div className={ css.portfolio_group } data-aut-id="Car Trade">
                <ImageWrapper
                    fileName={ fileNames.CT_CARTRADE_TECH_LOGO }
                    types={ SVG_TYPE }
                    alt="Car Trade"
                    className={ css.logoImg }
                />
            </div>
            {getBrands()}
        </div>
    );
};

export default FooterBrand;
