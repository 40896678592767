import React from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';
import experimentMap from './experimentMap';

export const withFeatureFlag = expId => WrappedComponent => {
    const Component = props => (
        <WrappedComponent
            { ...props }
            variant={ experimentMap[expId] || '' }
        />
    );

    Component.displayName = `withFeatureFlag(${WrappedComponent.displayName || WrappedComponent.name})`;

    return hoistNonReactStatic(Component, WrappedComponent);
};

export default withFeatureFlag;
