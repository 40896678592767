export const disclaimerData = {
    olxin: {
        questionSection: {
            heading: 'Do you have any doubt?',
            description: 'Check the answers in our',
            link: {
                text: 'FAQ',
                url: '/faq'
            },
            contactSection: []
        }
    }
};
