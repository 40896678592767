import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'panamera-react-ui';
import { scrollToTop } from 'Helpers/page';

import css from '../Footer.scss';
import classNames from 'classnames';

const LinksColumn = ({ title, links, sectionId }) => {
    const list = links.map((link, index) => {
        function onClick() {
            if (!(link.href || '').includes('://')) {
                scrollToTop();
            }
        }
        return (<li key={ index }>
            <Link
                className={ css.links }
                to={ link.href }
                onClick={ onClick }
                target={ link.target ? link.target : '' }
                rel={ link.target ? 'nofollow noopener noreferrer' : '' }
            >
                { link.title }
            </Link>
        </li>);
    });

    return (
        <section className={ classNames(css.section) } data-aut-id={ `${sectionId}_column` } >
            { title }
            <ul>
                { list }
            </ul>
        </section>
    );
};

LinksColumn.propTypes = {
    title: PropTypes.string.isRequired,
    links: PropTypes.array.isRequired,
    sectionId: PropTypes.string
};
LinksColumn.defaultProps = {
    sectionId: ''
};

export default LinksColumn;
