import React from 'react';
import ReactObserver from 'Components/ReactObserver/ReactObserver';
import PropTypes from 'prop-types';

export class LoadMoreObserver extends React.PureComponent {
    static propTypes = {
        loadMore: PropTypes.func.isRequired
    };

    render() {
        return (
            <ReactObserver triggerOnce="true">
                {({ inView, ref }) => {
                    if (inView) {
                        this.props.loadMore();
                    }
                    return (
                        <div ref={ ref } />
                    );
                }}
            </ReactObserver>
        );
    }
}

export default LoadMoreObserver;
