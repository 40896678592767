import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { FormattedMessage as Translation } from 'react-intl';
import classNames from 'classnames';
import { COLUMN_ICONS, TOP_COLUMN_LINKS } from 'Constants/footer';
import { isEmpty } from 'Helpers/objects';
import { addUserInfoInLinks, hasCorporateSection } from 'Helpers/seoFooter';
import css from '../Footer.scss';
import LinksColumn from '../Columns/LinksColumn';
import IconsColumn from '../Columns/IconsColumn';
import withConfig from 'HOCs/withConfig/withConfig';
import DisclaimerDesktop from './Disclaimer.desktop';
import { getLoggedUser } from 'Selectors/user';
import withSessionLong from 'HOCs/withSessionLong/withSessionLong';
export class Top extends React.Component {
    static propTypes = {
        sections: PropTypes.array,
        country: PropTypes.object,
        brand: PropTypes.string,
        zendeskUrl: PropTypes.string,
        config: PropTypes.shape({
            get: PropTypes.func.isRequired
        }),
        track: PropTypes.func.isRequired,
        isOlxAutosLandingPage: PropTypes.bool,
        isPaidCampaign: PropTypes.bool,
        userId: PropTypes.string,
        userSessionLong: PropTypes.string
    };

    static defaultProps = {
        sections: [],
        country: {},
        brand: '',
        isPaidCampaign: false
    };

    constructor(props) {
        super(props);

        const { config } = this.props;

        this.ppcColumns = config.get('ppcColumns');
    }

    getLinksColumns = rawSections => {
        const sections = rawSections;
        const { track, isOlxAutosLandingPage, isPaidCampaign, config, userId, userSessionLong } = this.props;
        const addUserInFooterLinks = config.get('addUserInFooterLinks');

        return sections
            .filter(section => {
                return isOlxAutosLandingPage
                && isPaidCampaign
                && this.ppcColumns ? this.ppcColumns.includes(section.id) : TOP_COLUMN_LINKS.includes(section.id);
            })
            .map(section => {
                const links = addUserInFooterLinks
                    && addUserInFooterLinks.length > 0
                    && addUserInFooterLinks.indexOf(section.id) >= 0
                    && addUserInfoInLinks(section.links, userSessionLong, userId)
                    || section.links;

                return (<LinksColumn
                    key={ section.id }
                    title={ section.title }
                    links={ links }
                    track={ track }
                    sectionId={ section.id }
                />);
            });
    }

    getBrandSection = (country, brand, zendeskUrl) => {
        const section = {
            id: 'brand-section',
            title: brand,
            links: []
        };

        if (zendeskUrl) {
            section.links.push({
                title: <Translation id="help" />,
                href: zendeskUrl
            });
        }

        section.links.push({
            title: <Translation id="sitemap" />,
            href: '/sitemap/most-popular'
        });

        if (country.privacy_links) {
            section.links.push({
                title: <Translation id="terms_of_use" />,
                href: country.privacy_links.terms_url
            });

            section.links.push({
                title: <Translation id="privacy_policy" />,
                href: country.privacy_links.privacy_url
            });
        }

        return section;
    };

    getIconsColumn = rawSections => {
        const sections = rawSections.filter(section => COLUMN_ICONS.includes(section.id));
        const { track } = this.props;

        return <IconsColumn sections={ sections } brand={ this.props.brand } track={ track } />;
    };

    render() {
        const { sections: rawSections, country, brand, zendeskUrl, track } = this.props;

        if (rawSections.length === 0) {
            return null;
        }

        const linksColumns = this.getLinksColumns(rawSections);

        if (!hasCorporateSection(rawSections) && !isEmpty(country)) {
            const section = this.getBrandSection(country, brand, zendeskUrl);

            linksColumns.push(
                <LinksColumn
                    key={ section.id }
                    title={ section.title }
                    links={ section.links }
                    track={ track }
                    sectionId={ section.id }
                />
            );
        }

        const iconColumns = this.getIconsColumn(rawSections);

        return (
            <>
                <div className={ css.topFooter } data-aut-id="top-desktop-footer">
                    <div className={ classNames(css.sections) }>
                        <section className={ css.columnsSection }>
                            { linksColumns }
                        </section>
                        { iconColumns }
                    </div>
                </div>
                <DisclaimerDesktop />
            </>
        );
    }
}

export const mapStateToProps = state => ({
    userId: getLoggedUser(state).id
});

export default compose(
    withConfig,
    withSessionLong
)(Top);
