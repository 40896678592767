import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Link } from 'panamera-react-ui';

import Icon from './Icon';
import css from '../Footer.scss';

export const IconsColumn = ({ sections, brand }) => {
    let socialNetworks = null;
    let mobileApps = null;

    sections.forEach(({ id, title, links }) => {
        if (id === 'social-networks') {
            const networks = links.map((link, index) => {
                function onClick() {
                }
                return (<Link
                    key={ index }
                    className={ css.links }
                    to={ link.href }
                    onClick={ onClick }
                    data-aut-id={ link.title }
                >
                    <Icon type="networks" target={ link.title } />
                </Link>);
            });

            socialNetworks = (
                <div className={ classNames(css.withIcons) }>
                    <span className={ classNames(css.title) }>{ title }</span>
                    <div className={ classNames(css.linksContainer) }> { networks } </div>
                </div>
            );
        }

        if (id === 'mobile-apps') {
            const apps = links.map((link, index) => (
                <Link
                    key={ index }
                    className={ css.links }
                    to={ link.href }
                >
                    <Icon type="apps" target={ link.title } brand={ brand } />
                </Link>
            ));

            mobileApps = (
                <div className={ classNames(css.withIcons, css.apps) }>
                    { apps }
                </div>
            );
        }
    });

    return (
        <section className={ classNames(css.section, css.iconSection) }>
            { socialNetworks }
            { mobileApps }
        </section>
    );
};

IconsColumn.propTypes = {
    sections: PropTypes.array.isRequired,
    brand: PropTypes.string.isRequired
};

export default IconsColumn;
