/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'panamera-react-ui';

import { buildURL } from 'Helpers/url';
import css from './SearchAllCategories.scss';
import withConfig from 'HOCs/withConfig/withConfig';
import withRouter from 'HOCs/withRouter';
import { compose } from 'redux';
import { OLX_RENEW_MOBILE_NAME, OLX_RENEW_MOBILE_CAT_ALL_L2 } from 'Constants/constants';
import { configSelector, getLocale } from 'Selectors/config';
import { getCategoryTagText } from 'Helpers/categories';

export const SearchAllSubCategoryRow = ({ onClick, category, className, config = {}, router = {}, categoriesTag = {}, currentLocale = '', selectedLocation = {}, showCategoryTag = false }) => {
    const {
        isEnabled: isRedirectionEnabled = false,
        category: redirectionCategory
    } = config.get('redirectToBuyLandingPage') || {};

    const buyLandingPageUrl = config.get('landingPageRoutes', 'buy-organic') || config.get('landingPageRoutes', 'listing-letgo');

    const isCarCategory = isRedirectionEnabled && category.id === redirectionCategory;

    const finalCategory = category.sub_categories?.length === 1 ? category.sub_categories[0] : category;

    const isNewCategoryTag = Object.prototype.hasOwnProperty.call(categoriesTag, category.id)
    && (categoriesTag[category.id]?.city_ids?.includes(String(selectedLocation?.id)) || categoriesTag[category.id]?.city_ids?.includes(String(selectedLocation?.parentId)));

    const handleClick = event => {
        if (event && event.preventDefault) {
            event.preventDefault();
        }

        if (isCarCategory) {
            router.push(buyLandingPageUrl);
            return '';
        }

        return onClick(finalCategory);
    };

    return (
        <div className={ classnames(css.subCategory, className) }>
            {((category.id === OLX_RENEW_MOBILE_NAME || category?.id === OLX_RENEW_MOBILE_CAT_ALL_L2) && category.enabled)
                ? <a title={ category.name } data-aut-id={ OLX_RENEW_MOBILE_NAME } onClick={ handleClick } className={ css.subCategoryLink } href={ category?.redirection_url }>
                    <span>
                        { category.name }
                        <div className={ classnames({ [css.newBage]: category?.id === OLX_RENEW_MOBILE_CAT_ALL_L2 }) }>
                            {category?.id === OLX_RENEW_MOBILE_CAT_ALL_L2 && category?.category_name_postfix}
                        </div>
                    </span>
                </a>
                : <>
                    <Link
                        className={ css.subCategoryLink }
                        onClick={ handleClick }
                        to={ isCarCategory ? `${buyLandingPageUrl}`
                            : buildURL({ category: finalCategory, location: selectedLocation }) }
                        alt={ category.name }
                    >
                        <span data-aut-id={ 'header_link' }>
                            { category.name }
                            {isNewCategoryTag && showCategoryTag
                                && <div className={ css.newBage }>
                                    {getCategoryTagText(categoriesTag, category.id, currentLocale)}
                                </div>
                            }
                        </span>
                    </Link>
                </>
            }
        </div>
    );
};

SearchAllSubCategoryRow.propTypes = {
    onClick: PropTypes.func.isRequired,
    category: PropTypes.object.isRequired,
    className: PropTypes.string,
    config: PropTypes.shape({
        get: PropTypes.func
    }),
    router: PropTypes.object,
    categoriesTag: PropTypes.object,
    currentLocale: PropTypes.string,
    selectedLocation: PropTypes.object,
    showCategoryTag: PropTypes.bool
};

export const mapStateToProps = state => {
    const categoriesTag = configSelector(state)?.categories_tag;

    return {
        categoriesTag,
        currentLocale: getLocale(state),
        selectedLocation: state?.locations?.selectedLocation
    };
};

export default compose(withConfig, withRouter, connect(mapStateToProps))(SearchAllSubCategoryRow);
