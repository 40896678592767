export const hasCorporateSection = sections => !!sections.find(({ id }) => id === 'corporate-links');

export const addUserInfoInLinks = (links, sessionId, userId) => {
    return links.map(link => {
        let modifiedLink = link.href;

        modifiedLink = modifiedLink.replace('${SessionId}', sessionId);
        modifiedLink = modifiedLink.replace('${UserId}', userId);
        return { ...link, href: modifiedLink };
    });
};

export const isAtCityLevelAndCategoryTreeSelected = (categoryTreeSelected, selectedLocation) => {
    const isValidCategoryTree = !!(categoryTreeSelected && categoryTreeSelected.L1 && (categoryTreeSelected.L2 || typeof categoryTreeSelected.L2 === 'undefined'));

    return selectedLocation?.type === 'CITY' && isValidCategoryTree;
};
