import React from 'react';
import PropTypes from 'prop-types';
import css from './NotificationContainer.desktop.scss';
import { Loader } from 'panamera-react-ui';
import AsyncNotificationsList from './AsyncNotificationsList';
import { FormattedMessage as Translation } from 'react-intl';
import EmptyState from './EmptyState';
import classNames from 'classnames';
import NewIconButton from 'Components/ThematicNewIconButton/ThematicNewIconButton';

export class NotificationContainer extends React.PureComponent {
    static propTypes = {
        notifications: PropTypes.array,
        onNotificationsClick: PropTypes.func.isRequired,
        onBadgeClick: PropTypes.func.isRequired,
        showLoader: PropTypes.bool,
        unRead: PropTypes.bool,
        total: PropTypes.number,
        onSeeMoreClick: PropTypes.func.isRequired
    };

    static defaultProps = {
        notifications: [],
        showLoader: false,
        unRead: false,
        total: 100
    };

    constructor() {
        super();

        this.state = {
            showNotifications: false
        };
    }

    componentDidMount() {
        if (typeof document !== 'undefined') {
            document.addEventListener('click', this.handleClickOutside, true);
        }
    }

    componentWillUnmount() {
        if (typeof document !== 'undefined') {
            document.removeEventListener('click', this.handleClickOutside, true);
        }
    }

    handleClickOutside = event => {
        const domNode = this.notificationHub;

        if (!domNode || !domNode.contains(event.target)) {
            this.setState({
                showNotifications: false
            });
        }
    }

    toggleNotifications = () => {
        this.setState({
            showNotifications: !this.state.showNotifications
        }, () => {
            this.props.onBadgeClick(this.state.showNotifications);
        });
    }

    onSeeMoreClick = () => {
        const { notifications } = this.props;
        const lastNotification = notifications[notifications.length - 1];

        this.props.onSeeMoreClick(lastNotification);
    }

    onNotificationsClick = notification => {
        this.toggleNotifications();
        this.props.onNotificationsClick(notification);
    }

    render() {
        const { notifications, showLoader, total, unRead } = this.props;
        const { showNotifications } = this.state;
        let content;
        const showMore = total > notifications.length;

        if (showLoader && showNotifications && !notifications.length) {
            content = (
                <div
                    className={ css.notificationsContainer }
                    data-aut-id="loader"
                >
                    <div className={ `${ css.loader } ${ css.big }` }>
                        <Loader
                            containerSize="60px"
                        />
                    </div>
                </div>);
        }
        else if (showNotifications && notifications.length) {
            content = (
                <div
                    className={ css.notificationsContainer }
                >
                    { showLoader
                        && <div
                            className={ `${ css.loader } ${ css.small }` }
                            data-aut-id="loader"
                        >
                            <Loader
                                containerSize="30px"
                            />
                        </div>
                    }
                    <AsyncNotificationsList
                        notifications={ notifications }
                        onNotificationsClick={ this.onNotificationsClick }
                        seeMore={ total > notifications.length }
                    />
                    { showMore
                        && (
                            <div
                                className={ css.seeMore }
                                onClick={ this.onSeeMoreClick }
                                data-aut-id="seeMore"
                            >
                                <Translation id="showMore" />
                            </div>
                        )
                    }
                </div>
            );
        }
        else if (showNotifications) {
            content = (
                <div
                    className={ css.notificationsContainer }
                >
                    <EmptyState />
                </div>
            );
        }

        return (
            <div
                className={ classNames(
                    css.notificationHub,
                    {
                        [css.unReadNotification]: unRead
                    }
                ) }
                data-aut-id="notificationContainer"
                ref={ element => {
                    this.notificationHub = element;
                } }
            >
                <NewIconButton
                    name="notifications"
                    icon="notifications"
                    onClick={ this.toggleNotifications }
                    data-aut-id="badge"
                    active={ showNotifications }
                />
                { content }
            </div>
        );
    }
}

export default NotificationContainer;
